import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import useFetch from 'use-http';
import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import styles from './styles.module.scss';
import { $qrcode, $store, $storeList, setQRCode } from 'models/store';
import { useUnit } from 'effector-react';
import { $user } from 'models/user';
import { GroupsEnum } from 'types/users';
import { IStore } from 'dto/store.dto';

const interval$ = interval(28800000).pipe(map(i => i));

const isMobile = /Mobi|Android/i.test(window.navigator.userAgent)

export default function QRCode() {
	const navigate = useNavigate();
	const { post } = useFetch();

    const store = useUnit($store);
	const user = useUnit($user);
	const storeList = useUnit($storeList);
    const qrcode = useUnit($qrcode);

	const createCode = async (storeId: string) => {
		return await post('/api/qrcode/new', { storeId });
	};

	React.useEffect(() => {
		if (store?.id) {
			createCode(store.id).then((data: any) => setQRCode(data.code));

			const subscription = interval$.subscribe(async () => {
				if (store?.id) {
					const qrcodeData: any = await createCode(store.id);
					setQRCode(qrcodeData.code);
	
				}
			});
			return () => subscription.unsubscribe();
		}
	}, [store]);

	const handleUpdate = React.useCallback(() => {
		if (store?.id) {
			createCode(store.id).then((data: any) => setQRCode(data.code));
		}
	}, [store]);

	const handleRedirect = (url: string) => {
		navigate(url);
	}

	console.log('QRCODE', {storeList})

	return (
		<div className={styles.agreement}>
			<p>ВАШ МАГАЗИН</p>
			<h2 style={{ margin: '10px 0 15px 0', fontSize: '30px' }}><div className={styles["h2-title"]}>{store?.number} {store?.title}</div></h2>

			{!!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.GEN_QRCODE) > -1 && (
				<QRCodeSVG value={qrcode} />
			)}
			<h2>{ moment().format('dddd D MMMM YYYY')}</h2>
		
			{storeList?.filter((item: IStore, index, self) => {
				return index === self.findIndex(t => (
					t.id === item.id
				))
			}).length > 1 && (<div className={styles["btn-section"]}>
				<Button variant="green" onClick={() => handleRedirect('/section')}>
					Сменить секцию
				</Button>
			</div>)}
			
			{!!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.OUTSOURCE) > -1 &&  storeList?.length && (
				<div className={styles.btn}>
					<Button variant="violet" block onClick={() => handleRedirect('/outsourcing')}>
						Аутсорс
					</Button>
				</div>
			)}
			{!!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.MANUAL_REG) > -1 && isMobile && (<div style={{ margin: '20px' }}>
				<Button variant="violet" block onClick={() => handleRedirect('/manual')}>
					Зарегистрировать сотрудника вручную
				</Button>
			</div>)}
			{!!user && Array.isArray(user.groups) && (user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1 && storeList?.length > 0)&& (
				<div className={styles.btn}>
					<Button variant="violet" block onClick={() => handleRedirect('/admin')}>
						Статистика присутствия
					</Button>
				</div>
			)}
			{!!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.GEN_QRCODE) > -1 && (
				<div className={styles.btn}>
					<Button variant="violet" block onClick={handleUpdate}>
						Обновить QRcode
					</Button>
				</div>
			)}
		</div>
	);
}
